import PairingBaseEntity from "./PairingBaseEntity";
import {
  PairingBaseInterface,
  PairingInstallationInterface,
} from "./PairingInterface";
import moment from "moment";

export default class PairingInstallationEntity extends PairingBaseEntity {
  public address: string;
  public numUnit: number;

  constructor(data: PairingInstallationInterface) {
    const superInterface: PairingBaseInterface = {
      id: data.id,
      userId: data.userId,
      userEmail: data.userEmail,
      type: data.type,
      deviceId: data.deviceId,
      subscriptionNoWifi: data.subscriptionNoWifi,
      guest: data.guest,
      master: data.master,
      logicalId: data.logicalId,
    };
    super(superInterface);
    this.address = data.address;
    this.numUnit = data.numUnit;
  }

  get subscriptionPlanName(): string {
    return super.getSubscriptionPlanName();
  }

  get deviceNameForTable(): string {
    return super.getDeviceName();
  }

  get isMaster(): boolean {
    return this.master;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public calculateDisplayExpirationDate(t: any): string {
    if (super.isSubscriptionRenewed()) {
      const date = super.getRenewedDate();
      if (date) {
        return t.t("installationDetailsUsers.renewedOn", [
          moment(date).format("DD/MM/YYYY"),
        ]);
      }
    }
    if (super.isSubscriptionCancelled()) {
      const date = super.getCancelledDate();
      if (date) {
        return t.t("installationDetailsUsers.expiresOn", [
          moment(date).format("DD/MM/YYYY"),
        ]);
      }
    }
    if (super.isSubscriptionUnlimited()) {
      return t.t("installationDetailsUsers.unlimited");
    }
    return "";
  }
}
