import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import { EMAIL_REGEX } from "@/ui/helpers/FormRules";
import PairingController from "@/domain/pairing/PairingController";
import { AddGuestInterface } from "@/domain/pairing/PairingInterface";
import { AxiosError } from "axios";
import UserController from "@/domain/users/UserController";

@Component({
  components: { FrmxDialog },
})
export default class AddUserDialog extends Vue {
  @Prop({ default: "" }) public deviceId!: string;
  @Prop({ default: "" }) public masterId!: string;
  @Prop({ default: "" }) public masterEmail!: string;
  @Prop({ default: false }) public value!: boolean;

  public visibleDialog = false;
  public visibleDialogOk = false;
  public visibleDialogKo = false;
  public errorMessage = "";
  public loading = false;

  @Watch("value")
  private onValueChange(value: boolean) {
    this.visibleDialog = value;
    if (this.visibleDialog === false) {
      this.resetValues();
    }
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  public onClickCancel() {
    this.onVisibleDialogChange(false);
  }

  public onClickClose() {
    this.visibleDialogOk = false;
    this.visibleDialogKo = false;
  }

  // #region Añadir un invitado.
  public async onClickAdd() {
    this.loading = true;
    await this.validateEmailGuest();
    this.validateReason();
    if (this.isFormOk()) {
      this.addGuest();
    } else {
      this.loading = false;
    }
  }

  /** Añadir un invitado. */
  private addGuest() {
    const ctrl = new PairingController();
    const params: AddGuestInterface = {
      guestEmail: this.emailGuestValue,
      deviceId: this.deviceId,
      masterId: this.masterId,
      reason: this.reasonValue,
      guestType: "USER",
    };

    ctrl
      .addGuest(params)
      .then(() => {
        this.onVisibleDialogChange(false);
        this.visibleDialogOk = true;
        this.$emit("add");
      })
      .catch(this.addGuestHandleError)
      .finally(() => {
        this.loading = false;
      });
  }

  /** Manejar errores al añadir un invitado. */
  private addGuestHandleError(
    error: AxiosError<{
      title: string;
      status: number;
      detail: string;
    }>
  ) {
    this.errorMessage = "";
    if (error.response?.data.status === 409) {
      this.emailGuestErrors = [
        this.$t("general.inputErrors.emailAlreadyExists").toString(),
      ];
    } else {
      this.errorMessage = error.response?.data.detail ?? "";
      this.onVisibleDialogChange(false);
      this.visibleDialogKo = true;
    }
  }

  // #endregion

  private resetValues() {
    this.emailGuestValue = "";
    this.reasonValue = "";
    this.emailGuestErrors = [];
    this.reasonErrors = [];
  }

  private isFormOk() {
    return this.emailGuestErrors.length === 0 && this.reasonErrors.length === 0;
  }

  // #region Email del invitado.
  public emailGuestValue = "";
  public emailGuestErrors: Array<string> = [];

  public onChangeEmailGuest() {
    this.validateEmailGuest();
  }

  public async validateEmailGuest() {
    if (!this.emailGuestValue) {
      // Campo requerido.
      this.emailGuestErrors = [
        this.$t("general.inputErrors.required").toString(),
      ];
      return;
    }
    if (!EMAIL_REGEX.test(this.emailGuestValue)) {
      // Formato de correo electrónico.
      this.emailGuestErrors = [
        this.$t("general.inputErrors.invalidEmail").toString(),
      ];
      return;
    }
    // Correo electrónico existente en la base de datos.
    const ctrl = new UserController();
    await ctrl
      .getUserIdByEmail(this.emailGuestValue)
      .then(() => {
        this.emailGuestErrors = [];
      })
      .catch(() => {
        this.emailGuestErrors = [
          this.$t("general.inputErrors.emailNotFound").toString(),
        ];
      });
  }
  // #endregion

  // #region Motivo de la invitación.
  public reasonValue = "";
  public reasonErrors: Array<string> = [];

  public onChangeReason() {
    this.validateReason();
  }

  public validateReason() {
    if (!this.reasonValue) {
      this.reasonErrors = [this.$t("general.inputErrors.required").toString()];
    } else {
      this.reasonErrors = [];
    }
  }
  // #endregion
}
