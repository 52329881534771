import { SubscriptionNoWifiInterface } from "./SubscriptionNoWifiInterface";

export default class SubscriptionNoWifiEntity {
  public id: string;
  public code: string;
  public type: string;
  //public logicalId: string;
  //public userId: string;
  //public deviceId: string;
  public createDate: string;
  public expiredDate: string;
  public trial: true;
  public trialEnded: true;
  //public enabled: true;
  public freeRenewalDate: string;
  public product: {
    productId: string;
    price: number;
    currency: string;
    duration: string;
    state: string;
    updateDate: string
  };
  public features: [
    {
      feature: string;
      value: number;
      current: number;
    }
  ];

  constructor(data: SubscriptionNoWifiInterface) {    
    this.id = data.id;
    this.code = data.code;
    this.type = data.type;
    //this.logicalId = data.logicalId;
    //this.userId = data.userId;
    //this.deviceId = data.deviceId;
    this.createDate = data.createDate;
    this.expiredDate = data.expiredDate;
    this.trial = data.trial;
    this.trialEnded = data.trialEnded;
    //this.enabled = data.enabled;
    this.freeRenewalDate = data.freeRenewalDate;
    this.product = data.product;
    this.features = data.features;
  }

  public getCommercialName(language: string): string {
    if (this.trial && !this.trialEnded) {
      if (language === "es") {
        return "Periodo de prueba";
      } else {
        return "Trial";
      }
    }
    if (this.isTypeFree()) {
      if (language === "es") {
        return "Plan gratuito";
      } else {
        return "Free";
      }
    } else {
      if (this.product) {
        if (this.product.duration === "1y") {
          if (language === "es") {
            return "Plan ilimitado anual";
          } else {
            return "Unlimited Year";
          }
        }
        if (this.product.duration === "1m") {
          if (language === "es") {
            return "Plan ilimitado mensual";
          } else {
            return "Unlimited Month";
          }
        }
      }
      if (language === "es") {
        return "Ilimitado";
      } else {
        return "Unlimited";
      }
    }
  }

  /** Indica si el plan de la subscripción tipo gratuito. */
  public isFreePlan(): boolean {
    return !this.isTrialPlan() && this.isTypeFree();
  }

  /** Indica si el plan de la subscripción es de tipo trial. */
  public isTrialPlan(): boolean {
    return this.trial && !this.trialEnded;
  }

  /** Indica si el tipo es FREE, puede ser FREE o UNLIMITED. */
  public isTypeFree(): boolean {
    return this.type === "FREE";
  }

  /** Obtiene la fecha de cancelación de la suscripción. */
  public getCancelledDate(): string {
    return this.expiredDate;
  }

  /** Obtiene la fecha de renovación de la suscripción. */
  public getRenewalDate(): string {
    return this.freeRenewalDate;
  }

  /** Indica si la suscripción tiene fecha de renovación. */
  public isSubscriptionRenewed(): boolean {
    return !!this.freeRenewalDate;
  }

  /** Indica si la suscripción tiene fecha de cancelación. */
  public isSubscriptionCancelled(): boolean {
    return !!this.expiredDate;
  }

  public existsPrice(): boolean {
    return !!this.product?.price;
  }

  public existsCurrency(): boolean {
    return !!this.product?.currency;
  }

  public getPrice(): number {
    return this.product.price;
  }

  public getCurrency(): string {
    return this.product.currency;
  }

  public getCreateDate(): string {
    return this.createDate;
  }

  public getSubscriptionStartDate(): string {
    return this.product?.updateDate;
  }
}
