import PairingBaseEntity from "./PairingBaseEntity";
import {
  PairingBaseInterface,
  PairingDeviceInterface,
} from "./PairingInterface";
import moment from "moment";
import LanguagesHelper from "@/helpers/languagesHelper";

export default class PairingDeviceEntity extends PairingBaseEntity {
  public tag: string;
  public guest: boolean;
  public master: boolean;
  public status: string;
  public createdAt: string;
  public phoneModel: string;
  public phoneOS: string;
  public appBuild: string;
  public appVersion: string;

  constructor(data: PairingDeviceInterface) {
    const superInterface: PairingBaseInterface = {
      id: data.id,
      userId: data.userId,
      userEmail: data.userEmail,
      type: data.type,
      deviceId: data.deviceId,
      subscriptionNoWifi: data.subscriptionNoWifi,
      guest: data.guest,
      master: data.master,
      logicalId: data.logicalId,
    };
    super(superInterface);
    this.tag = data.tag;
    this.guest = data.guest;
    this.master = data.master;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.phoneModel = data.phoneModel;
    this.phoneOS = data.phoneOS;
    this.appBuild = data.appBuild;
    this.appVersion = data.appVersion;
  }

  getCommercialName(language: string) {
    return super.getSubscriptionPlanName(language);
  }

  isMonitor(): boolean {
    return super.isMonitor();
  }
  isPhone(): boolean {
    return super.isPhone();
  }
  isGuardUnit(): boolean {
    return super.isGuardUnit();
  }

  getType() {
    return super.getDeviceType();
  }

  public getNameIcon(): string {
    return super.getNameIcon();
  }

  getPhoneModel() {
    return this.phoneModel ? this.phoneModel : "-";
  }

  getPhoneOS() {
    return this.phoneOS ? this.phoneOS : "-";
  }

  getAppBuild() {
    return this.appBuild ? this.appBuild : "-";
  }

  getAppVersion() {
    return this.appVersion ? this.appVersion : "-";
  }

  /** Solo los dispositivos WIFI tienen identificador de plan de suscripción. */
  getPlanId(): number | undefined {
    return super.getPlanId();
  }

  canDelete() {
    return super.isNoWifi() && super.isCodeFree();
  }

  /**
   * Obtiene la fecha de suscripción en formato "DD/MM/YYYY".
   * Si no tiene fecha de suscripción, devuelve "-".
   */
  public getSubscriptionStartDateFormat(): string {
    const date = super.getSubscriptionStartDate();
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    }
    return "-";
  }

  /**
   * Obtiene la fecha de cancelación de la suscripción en formato "DD/MM/YYYY".
   * Si no tiene fecha de cancelación, devuelve "-".
   */
  public getCancelledDateFormat(): string {
    const date = super.getCancelledDate();
    if (date) {
      const language = LanguagesHelper.getInstance().getLanguage();
      moment.locale(language);
      return moment(date).format("LL");
    }
    return "-";
  }

  public getExpiredDateFormatIso(): string {
    const date = super.getCancelledDate();
    return date ? moment(date).format("YYYY-MM-DD") : "-";
  }

  /**
   * Obtiene la fecha de renovación de la suscripción en formato "DD/MM/YYYY".
   * Si no tiene fecha de renovación, devuelve "-".
   */
  public getRenewedDateFormat(): string {
    const date = super.getRenewedDate();
    if (date) {
      const language = LanguagesHelper.getInstance().getLanguage();
      moment.locale(language);
      return moment(date).format("LL");
    }
    return "-";
  }

  public getPriceFormat(): string | undefined {
    const price =
      super.existsPrice() &&
      super.existsCurrency() &&
      !super.isPlanFree() &&
      !super.isTrialPlan()
        ? super.getPrice()
        : 0;

    return Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: super.getCurrency(),
      minimumFractionDigits: 2,
    }).format(price);
  }

  public canActivateTrial(): boolean {
    return super.isPlanFree();
  }

  public canExtendTrialPeriod(): boolean {
    return super.isTrialPlan();
  }

  public getCreateDateIso(): string {
    const date = super.getCreateDate();
    return date ? moment(date).format("YYYY-MM-DD") : "-";
  }

  public isTrial(): boolean {
    return super.isTrialPlan();
  }

  public isFree(): boolean {
    return super.isPlanFree();
  }
}
