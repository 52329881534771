import SubscriptionNoWifiEntity from "./SubscriptionNoWifiEntity";
import SubscriptionNoWifiService from "./SubscriptionNoWifiService";

export default class SubscriptionNoWifiController {
  protected service: SubscriptionNoWifiService;

  constructor() {
    this.service = new SubscriptionNoWifiService();
  }

  /**
   * Activar periodo de prueba.
   * Debe estar en plan gratuito o dará error.
   * @param userId Id del usuario.
   * @param loginalId Id de ???.
   * @param expiredDate Fecha de expiración en formato ISO extendido (YYYYYY-MM-DD).
   */
  public async activateTrial(
    userId: string,
    loginalId: string,
    expiredDate: string
  ): Promise<SubscriptionNoWifiEntity> {
    const days = this.getDaysDiff(expiredDate);
    return this.service.activateTrial(userId, loginalId).then(async (data) => {
      if (days === 30) {
        return new SubscriptionNoWifiEntity(data);
      } else {
        return await this.extendTrial(loginalId, expiredDate);
      }
    });
  }

  /**
   * Extender el periodo de pruerba.
   * Debe estar en plan trial, o con un trial expirado sino dará error.
   * @param logicalId Id de ???.
   * @param expiredDate Fecha de expiración en formato ISO extendido (YYYYYY-MM-DD).
   */
  public async extendTrial(
    logicalId: string,
    expiredDate: string
  ): Promise<SubscriptionNoWifiEntity> {
    return this.service
      .extendTrial(logicalId, expiredDate)
      .then(async (data) => {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        return new SubscriptionNoWifiEntity(data);
      });
  }

  /**
   * Calcular la diferencia de días entre la fecha actual y la fecha de expiración.
   * @param expiredDateISO Fecha de expiración en formato ISO extendido (YYYYYY-MM-DD).
   */
  private getDaysDiff(expiredDateISO): number {
    const currentDate = new Date();
    const expiredDate = new Date(expiredDateISO);
    const differenceInTime = expiredDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }
}
