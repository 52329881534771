/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeviceInterface } from "../interfaces/DeviceInterface";

const defaultDevice: DeviceInterface = {
  accuracy: 0,
  area: "",
  bluid: 0,
  city: "",
  connectable: false,
  continent: "",
  countryiso2: "",
  deployed: false,
  //divertCalls: false,
  divertService: false,
  family: "",
  fwVersionV2: {},
  geoLocation: {},
  iccid: "",
  id: "",
  imei: "",
  installationId: {},
  ipAddress: {},
  lastActivityDate: 0,
  lastTelemetryDate: {},
  latitude: 0,
  longitude: 0,
  manufacturingDate: 0,
  name: "",
  numBlock: 0,
  numSubblock: 0,
  ol: "",
  photocaller: false,
  primaryThumbprint: {},
  province: "",
  serialNumber: "",
  simDateUpdated: {},
  simStatus: "",
  status: false,
  subtype: "",
  subzone: "",
  timeZone: {},
  trialAvailable: false,
  trialEndDate: {},
  type: "",
  unitNumber: 0,
  versionHW: "",
  versionSW: "",
  wirelessSignal: 0,
  zone: "",
  videoQuality: null,
};
export default class DeviceEntity implements DeviceInterface {
  accuracy: number;
  area: string;
  bluid: number;
  city: string;
  connectable: boolean;
  continent: string;
  countryiso2: string;
  deployed: boolean;
  //divertCalls: boolean;
  divertService: boolean;
  family: string;
  fwVersionV2: any;
  geoLocation: any;
  iccid: string;
  id: string;
  imei: string;
  installationId: any;
  ipAddress: any;
  lastActivityDate: number;
  lastTelemetryDate: any;
  latitude: number;
  longitude: number;
  manufacturingDate: number;
  name: string;
  numBlock: number;
  numSubblock: number;
  ol: string;
  photocaller: boolean;
  primaryThumbprint: any;
  province: string;
  serialNumber: string;
  simDateUpdated: any;
  simStatus: string;
  status: boolean;
  subtype: string;
  subzone: string;
  timeZone: any;
  trialAvailable: boolean;
  trialEndDate: any;
  type: string;
  unitNumber: number;
  versionHW: string;
  versionSW: string;
  wirelessSignal: number;
  zone: string;
  videoQuality: string | null | undefined;
  constructor(device: DeviceInterface = defaultDevice) {
    this.accuracy = device.accuracy;
    this.area = device.area;
    this.bluid = device.bluid;
    this.city = device.city;
    this.connectable = device.connectable;
    this.continent = device.continent;
    this.countryiso2 = device.countryiso2;
    this.deployed = device.deployed;
    //this.divertCalls = device.divertCalls;
    this.divertService = device.divertService;
    this.family = device.family;
    this.fwVersionV2 = device.fwVersionV2;
    this.geoLocation = device.geoLocation;
    this.iccid = device.iccid;
    this.id = device.id;
    this.imei = device.imei;
    this.installationId = device.installationId;
    this.ipAddress = device.ipAddress;
    this.lastActivityDate = device.lastActivityDate;
    this.lastTelemetryDate = device.lastTelemetryDate;
    this.latitude = device.latitude;
    this.longitude = device.longitude;
    this.manufacturingDate = device.manufacturingDate;
    this.name = device.name;
    this.numBlock = device.numBlock;
    this.numSubblock = device.numSubblock;
    this.ol = device.ol;
    this.photocaller = device.photocaller;
    this.primaryThumbprint = device.primaryThumbprint;
    this.province = device.province;
    this.serialNumber = device.serialNumber;
    this.simDateUpdated = device.simDateUpdated;
    this.simStatus = device.simStatus;
    this.status = device.status;
    this.subtype = device.subtype;
    this.subzone = device.subzone;
    this.timeZone = device.timeZone;
    this.trialAvailable = device.trialAvailable;
    this.trialEndDate = device.trialEndDate;
    this.type = device.type;
    this.unitNumber = device.unitNumber;
    this.versionHW = device.versionHW;
    this.versionSW = device.versionSW;
    this.wirelessSignal = device.wirelessSignal;
    this.zone = device.zone;
    this.videoQuality = device.videoQuality;
  }

  /** Indica si tiene familia. */
  public hasFamily(): boolean {
    return this.family !== "";
  }

  /** Indica si es un teléfono. */
  public isPhone(): boolean {
    return this.family === "PHONE";
  }

  /** Indica si es un teléfono VEO. */
  public isPhoneVeo(): boolean {
    return this.isPhone() && this.type === "VEO";
  }

  /** Indica si es un teléfono ILOFT. */
  public isPhoneILoft(): boolean {
    return this.isPhone() && this.type === "ILOFT";
  }

  /** Indica si es un teléfono EXTRA. */
  public isPhoneExtra(): boolean {
    return this.isPhone() && this.type === "EXTRA";
  }

  /** Indica si es un teléfono BASICPLUS. */
  public isPhoneBasicPlus(): boolean {
    return this.isPhone() && this.type === "BASICPLUS";
  }

  /** Indica si es un Monitor. */
  public isMonitor(): boolean {
    return this.family === "MONITOR";
  }

  /** Indica si es un Monitor No Wifi. */
  public isMonitorNoWifi(): boolean {
    return this.family === "MONITOR" && this.subtype === "NOWIFI";
  }

  /** Indica si es un Monitor VEO-XL. */
  public isMonitorVEOXL(): boolean {
    return this.isMonitor() && this.type === "VEO-XL";
  }

  /** Indica si es un Panel. */
  public isPanel(): boolean {
    return this.family === "PANEL";
  }

  /** Indica si es un Panel 4G. */
  public isPanel4G(): boolean {
    return this.family === "PANEL" && this.subtype === "4G";
  }

  /** Indica si es un EDIBOX. */
  public isEdibox(): boolean {
    return this.family === "EDIBOX";
  }

  /** Indica si es una consergería. */
  public isGuardUnit(): boolean {
    return this.family === "GUARDUNIT";
  }

  /**
   * Indica si puede ver los emparejados.
   * Excluye a los panles 4g, edibox, teléfonos.
   * Quedan los monitores wifi, monitores nowifi y consergería.
   */
  public showTablePaired(): boolean {
    return (
      this.hasFamily() &&
      !this.isPanel4G() &&
      !this.isEdibox() &&
      !this.isPhone()
    );
  }

  /** Indica si puede ver la tabla de desvío de llamada. */
  public showTableCallDivert(): boolean {
    return this.isPanel4G();
  }

  /**
   * Indica si puede ver el estado del desvio de llamada.
   * Para mostrarlo debe ser un teléfono o un monitor no wifi.
   */
  public showDivertCall(): boolean {
    return this.isPhone() || this.isMonitorNoWifi();
  }

  /** Si la instalación existe. */
  public existsInstallationId(): boolean {
    return this.installationId !== null;
  }

  /** Obtien el logicalId uniendo los campos numBlock, numSubblock y unitNumber. */
  public getLogisticId(): string {
    if (!this.existsInstallationId()) {
      return "";
    }
    let r = "";
    r += this.numBlock.toString().padStart(2, "0");
    r += this.numSubblock.toString().padStart(2, "0");
    r += this.unitNumber.toString().padStart(2, "0");
    return r;
  }

  /** Solo los teléfonos VEO, ILOFT, EXTRA y BASICPLUS pueden generar el código QR. */
  public canGenerateQrCode(): boolean {
    return (
      this.isPhoneVeo() ||
      this.isPhoneILoft() ||
      this.isPhoneExtra() ||
      this.isPhoneBasicPlus()
    );
  }

  /** Solo los paneles 4G pueden acceder a Grafana. */
  public canGrafana(): boolean {
    return this.isPanel4G();
  }

  /** Solo los paneles 4G  y los monitores VEO-XL pueden acceder a la calidad de video. */
  public showVideoQuality(): boolean {
    return this.isPanel4G() || this.isMonitorVEOXL();
  }
  /** Indica si la calidad de video es estándar. */
  public isVideoQualityEstandar(): boolean {
    return this.showVideoQuality() && this.videoQuality === "STANDARD";
  }
  /** Indica si la calidad de video es premium. */
  public isVideoQualityPremium(): boolean {
    return this.showVideoQuality() && this.videoQuality === "PREMIUM";
  }
}
