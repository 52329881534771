import DeviceCacheReaderEntity from "../deviceCacheReader/DeviceCacheReaderEntity";
import SubscriptionNoWifiEntity from "../subscriptionNoWifi/SubscriptionNoWifiEntity";
import SubscriptionWifiEntity from "../subscriptionWifi/SubscriptionWifiEntity";
import { PairingBaseInterface } from "./PairingInterface";

export default class PairingBaseEntity {
  public id: string;
  public userId: string;
  public userEmail: string;
  public type: "WIFI" | "NOWIFI";
  public deviceId: string;
  private subscriptionNoWifi: SubscriptionNoWifiEntity | undefined;
  private subscriptionWifi: SubscriptionWifiEntity | undefined;
  private deviceCacheReader: DeviceCacheReaderEntity | undefined;
  public guest: boolean;
  public master: boolean;
  public logicalId: string;

  protected constructor(data: PairingBaseInterface) {
    this.id = data.id;
    this.userId = data.userId;
    this.userEmail = data.userEmail;
    this.type = data.type;
    this.deviceId = data.deviceId;

    this.subscriptionWifi = undefined;
    if (data.subscriptionNoWifi) {
      this.subscriptionNoWifi = new SubscriptionNoWifiEntity(
        data.subscriptionNoWifi
      );
    } else {
      this.subscriptionNoWifi = undefined;
    }
    this.deviceCacheReader = undefined;

    this.guest = data.guest;
    this.master = data.master;
    this.logicalId = data.logicalId;
  }

  public get email() {
    return this.userEmail;
  }

  /** El emparejamiento es de tipo WIFI. */
  public isWifi() {
    return this.type === "WIFI";
  }

  /** El emparejamiento es de tipo NO WIFI. */
  public isNoWifi() {
    return this.type === "NOWIFI";
  }

  /** Asigna la información de la suscripción WIFI. */
  public setSubscriptionWifi(subscriptionWifi: SubscriptionWifiEntity) {
    this.subscriptionWifi = subscriptionWifi;
  }

  /** Asigna la información del dispositivo. */
  public setDeviceCacheReader(data: DeviceCacheReaderEntity) {
    this.deviceCacheReader = data;
  }

  /**
   * Obtiene el nombre del plan de la suscripción.
   * @param language Idioma en el que se desea obtener el nombre del plan.
   */
  public getSubscriptionPlanName(language = "es"): string {
    let name: string | undefined;
    if (this.isWifi()) {
      name = this.subscriptionWifi?.getCommercialName(language);
    } else {
      name = this.subscriptionNoWifi?.getCommercialName(language);
    }
    return name ? name : "-";
  }

  /** Obtiene el nombre del dispositivo. */
  public getDeviceName(): string {
    let r = "";
    if (this.deviceCacheReader) {
      r +=
        this.deviceCacheReader.family.charAt(0).toUpperCase() +
        this.deviceCacheReader.family.slice(1).toLowerCase();
      r += " ";
      r += this.deviceCacheReader.type.toUpperCase();
      r += " - ";
      if (this.deviceCacheReader.subtype === "WIFI") {
        r += "Wifi";
      } else if (this.deviceCacheReader.subtype === "NOWIFI") {
        r += "No wifi";
      } else {
        r += this.deviceCacheReader.subtype;
      }
    }
    return r;
  }

  public getSubscriptionStartDate(): Date | undefined {
    if (this.isWifi() && this.subscriptionWifi) {
      return new Date(this.subscriptionWifi.initialDate);
    }
    // En los NOWIFI aún no devuelve esa información.
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return new Date(this.subscriptionNoWifi.getSubscriptionStartDate());
    }
    return undefined;
  }

  public getSubscriptionCreateDate(): Date | undefined {
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return new Date(this.subscriptionNoWifi.getCreateDate());
    }
    return undefined;
  }

  /** Obtiene la fecha de renovación de la suscripción. */
  public getRenewedDate(): Date | undefined {
    if (
      this.isNoWifi() &&
      this.subscriptionNoWifi &&
      this.subscriptionNoWifi.isSubscriptionRenewed()
    ) {
      return new Date(this.subscriptionNoWifi.getRenewalDate());
    }
    return undefined;
  }

  /** Indica si la suscripción tiene fecha de renovación. */
  public isSubscriptionRenewed(): boolean {
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.isSubscriptionRenewed();
    }
    return false;
  }

  /** Obtiene la fecha de cancelación de la suscripción. */
  public getCancelledDate(): Date | undefined {
    if (
      this.isNoWifi() &&
      this.subscriptionNoWifi &&
      this.subscriptionNoWifi.isSubscriptionCancelled()
    ) {
      return new Date(this.subscriptionNoWifi.getCancelledDate());
    }
    return undefined;
  }

  /** Indica si la suscripción tiene fecha de cancelación. */
  public isSubscriptionCancelled(): boolean {
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.isSubscriptionCancelled();
    }
    return false;
  }

  /**
   * Indica si la suscripción es ilimitada.
   * Todos los WIFI la tienen ilimitada y los FREE de los NOWIFI.
   */
  public isSubscriptionUnlimited(): boolean {
    if (this.isWifi()) {
      return true;
    }
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.isTypeFree();
    }
    return false;
  }

  protected isCodeFree(): boolean {
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.isTypeFree();
    }
    return false;
  }

  public isMonitor(): boolean {
    return this.deviceCacheReader?.isMonitor() ?? false;
  }

  public isPhone(): boolean {
    return this.deviceCacheReader?.isPhone() ?? false;
  }

  public isGuardUnit(): boolean {
    return this.deviceCacheReader?.isGuardUnit() ?? false;
  }

  public getDeviceType(): string {
    return this.deviceCacheReader?.type ?? "";
  }

  protected getNameIcon(): string {
    return this.deviceCacheReader?.getNameIcon() ?? "";
  }

  public existsSubscription(): boolean {
    return (
      this.subscriptionWifi !== undefined ||
      this.subscriptionNoWifi !== undefined
    );
  }

  /** Solo los dispositivos WIFI tienen identificador de plan de suscripción. */
  getPlanId(): number | undefined {
    if (this.isWifi() && this.subscriptionWifi) {
      return this.subscriptionWifi.getPlanId();
    }
    return undefined;
  }

  protected existsPrice(): boolean {
    if (this.isWifi() && this.subscriptionWifi) {
      return this.subscriptionWifi.getPrice() !== undefined;
    }
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.existsPrice();
    }
    return false;
  }

  protected existsCurrency(): boolean {
    if (this.isWifi() && this.subscriptionWifi) {
      return this.subscriptionWifi.getCurrency() !== undefined;
    }
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.existsCurrency();
    }
    return false;
  }

  protected getPrice(): number {
    if (this.isWifi() && this.subscriptionWifi) {
      return this.subscriptionWifi.getPrice() ?? 0;
    }
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.getPrice() ?? 0;
    }
    return 0;
  }

  protected getCurrency(): string {
    if (this.isWifi() && this.subscriptionWifi) {
      return this.subscriptionWifi.getCurrency() ?? "EUR";
    }
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.getCurrency() ?? "EUR";
    }
    return "EUR";
  }

  protected isPlanFree(): boolean {
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.isFreePlan();
    }
    return false;
  }

  protected isTrialPlan(): boolean {
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.isTrialPlan();
    }
    return false;
  }

  protected getCreateDate(): string {
    if (this.isNoWifi() && this.subscriptionNoWifi) {
      return this.subscriptionNoWifi.getCreateDate();
    }
    return "";
  }
}
