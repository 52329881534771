<template lang="html" src="./Users.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";

import userService from "@/api/user";

import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppListMenu, {
  hasAllowedMenuOptions,
} from "@/components/AppListMenu.vue";
import AppChipInfo, { getChipInfoText } from "@/components/AppChipInfo.vue";
import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import AppSelect from "@/components/AppSelect.vue";

import ExportTableDataButton from "@/components/Table/ExportTableDataButton/ExportTableDataButton.vue";
import { PREDEFINED_STYLES } from "@/mixins/table-export.mixin";

import dateMixin from "@/mixins/date.mixin";
import tableBackMixin from "@/mixins/table.mixin/back";
import filterMixin from "@/mixins/filter.mixin";
import languagesMixin from "@/mixins/languages.mixin";
import countriesMixin from "@/mixins/countries.mixin";

import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import { translate } from "@/lang/i18n";

import UsersTable from "./UsersTable/UsersTable.vue";
import UserService from "@/api/user";

// Keys para persistir los valores en la sessionStorage con el objetivo de mantenerlo tras un refresco de la página
const keyView = "Users";
const keySearch = keyView + ".search";
const keyFilterOptions = keyView + ".filterOptions";

const YES_OR_NO_LITERALS = {
  true: "general.yes",
  false: "general.no",
};

const FILTER_DEFINITIONS = {
  emailFilter: {
    literal: "user.email",
  },
  langFilter: {
    literal: "menu.language",
    valueLiterals: {},
  },
  countryFilter: {
    literal: "general.country",
    valueLiterals: {},
  },
  dateValueStart: {
    literal: "user.rangeStart",
  },
  dateValueEnd: {
    literal: "user.rangeEnd",
  },
  privacyFilter: {
    literal: "user.privacy",
    valueLiterals: YES_OR_NO_LITERALS,
  },
  sharingFilter: {
    literal: "user.data",
    valueLiterals: YES_OR_NO_LITERALS,
  },
  disabledFilter: {
    literal: "user.disabledFilter",
  },
  pairingType: {
    literal: "user.device",
  },
};

@Component({
  components: {
    AppBreadcrumbs,
    AppListMenu,
    AppChipInfo,
    FilterTableHeader,
    AppFilterButtons,
    AppKpiChipInfo,
    AppInputDate,
    AppAutocomplete,
    AppSelect,
    ExportTableDataButton,
    UsersTable,
  },
  mixins: [
    dateMixin,
    tableBackMixin,
    filterMixin,
    languagesMixin,
    countriesMixin,
  ],
  methods: { ...mapActions({ getUsersList: "users/getUsersList" }) },
})
export default class Users extends Vue {
  $t = translate; // Context wrapper to use translations on <script> at runtime
  filterOptions!: any;
  filterOptions_!: any;
  axiosSource!: any;
  totalServerItems!: number;
  search!: string;
  getUsersList!: Function;
  checkHasAnyActiveFilter!: Function;
  saveFilters!: Function;
  transformTablePropsForServerRequest!: Function;
  isNotAxiosCancellation!: Function;
  configureAxiosCancelToken!: Function;
  getLanguageName!: Function;
  getCountries!: Function;
  getCountry!: Function;

  keySearch: string = keySearch;
  keyFilterOptions: any = keyFilterOptions;
  tableProps: any = {
    sortBy: ["email"],
  };

  yesNoOptions = [
    { label: this.$t(YES_OR_NO_LITERALS.true), value: "true" },
    { label: this.$t(YES_OR_NO_LITERALS.false), value: "false" },
  ];
  pairingTypeOptions = [
    {
      label: this.$t("user.deviceType.wifi"),
      value: "WIFI",
    },
    { label: this.$t("user.deviceType.nowifi"), value: "NO_WIFI" },
    { label: this.$t("user.deviceType.both"), value: "BOTH" },
    { label: this.$t("user.deviceType.none"), value: "NONE" },
  ];
  loadingData = false;
  //searchTable: string | null = null;

  get FILTER_DEFINITIONS() {
    let value = { ...FILTER_DEFINITIONS };

    value.langFilter.valueLiterals = i18nIsoLanguages.getNames(
      this.$route.params.lang
    );

    return value;
  }

  get hasAnyActiveFilter() {
    const filters = { ...this.filterOptions_ };
    if (filters.disabledFilter === false) delete filters.disabledFilter;

    return this.checkHasAnyActiveFilter(filters);
  }

  get languages() {
    const languages: any = [];

    for (let key in this.FILTER_DEFINITIONS.langFilter.valueLiterals) {
      languages.push({
        label: this.FILTER_DEFINITIONS.langFilter.valueLiterals[key],
        value: key,
      });
    }

    return languages;
  }

  get countries() {
    const countryNames = this.getCountries();
    const countries: any = [];

    for (let key in countryNames) {
      countries.push({
        label: countryNames[key],
        value: key,
      });
    }

    return countries;
  }

  get menuOptions() {
    return [
      {
        icon: "people",
        text: this.$t("user.userDetails"),
        to: (item) => ({ name: "UserDetails", params: { email: item.email } }),
        allowed: this.$ability.can("details", "users"),
      },
      {
        icon: "cloud_download",
        text: this.$t("user.export"),
        //action: (item) => this.exportData(item.email),
        allowed: this.$ability.can("exportDetails", "users"),
      },
    ];
  }

  get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }

  get usersList() {
    return this.$store.getters["users/usersList"];
  }

  get numberOfUsers() {
    return this.$store.getters["users/numberOfUsers"];
  }

  get headers() {
    const items: any = [
      {
        text: this.$t("user.email"),
        value: "email",
        sortable: true,
        export: {
          width: 120,
        },
      },
      {
        text: this.$t("menu.language"),
        value: "locale",
        sortable: false,
        export: {
          value: (row) => this.getLanguageName(row.locale.toLowerCase()),
        },
      },
      {
        text: this.$t("general.country"),
        value: "country",
        sortable: true,
        export: {
          value: (row) => (row.country ? this.getCountry(row.country) : ""),
        },
      },
      {
        text: this.$t("user.eDate"),
        value: "createdAt",
        sortable: true,
        export: {
          format: "datetime",
        },
      },
      {
        text: this.$t("user.privacy"),
        value: "acceptPrivacy",
        sortable: true,
        export: {
          width: 40,
          value: (row) =>
            this.$t("user." + getChipInfoText("user", row.acceptPrivacy)),
          style: (row) =>
            row.acceptPrivacy
              ? PREDEFINED_STYLES.SUCCESS
              : PREDEFINED_STYLES.FAILED,
        },
      },
      {
        text: this.$t("user.data"),
        value: "acceptSharing",
        sortable: true,
        export: {
          width: 40,
          value: (row) =>
            this.$t("user." + getChipInfoText("user", row.acceptSharing)),
          style: (row) =>
            row.acceptSharing
              ? PREDEFINED_STYLES.SUCCESS
              : PREDEFINED_STYLES.FAILED,
        },
      },
    ];

    if (this.menuOptionsAllowed)
      items.push({
        text: null,
        value: null,
        sortable: false,
        align: "right",
        width: "1%",
        sticky: true,
      });

    return items;
  }

  /** Número de usuario total. */
  numUsers = 0;

  constructor() {
    super();
    this.loadUserCount();
  }

  /** Carga el número de usuarios total. */
  loadUserCount() {
    UserService.getNumberOfUsers().then((res) => {
      this.numUsers = res.data;
    });
  }

  getWithoutPagination(page, size) {
    const pagination = this.transformTablePropsForServerRequest();
    pagination.rowsPerPage = size;
    pagination.page = page;

    return userService.getUsers(
      this.filterOptions_,
      pagination,
      this.search,
      pagination.sortBy
    );
  }

  refreshList() {
    this.loadingData = true;

    //const pagination = this.transformTablePropsForServerRequest();
    this.transformTablePropsForServerRequest();

    this.configureAxiosCancelToken();
    //this.searchTable = this.session_getItem("Users.search");

    /*this.getUsersList({
      filterOptions: this.filterOptions_,
      pagination: pagination,
      search: this.search ? this.search.trim() : null,
      column: pagination.sortBy,
      axiosSource: this.axiosSource,
      isOpenditRole: this.isOpenditRole,
    })
      .then((res) => {
        this.$store.dispatch("users/getNumberOfUsers");
        this.totalServerItems = res.totalElements;

        setTimeout(() => {
          this.loadingData = false;
        }, 500);
      })
      .catch((err) => {
        if (this.isNotAxiosCancellation(err)) {
          console.error(err);
          this.loadingData = false;
        }
      });*/
  }

  onFilterSubmit(e) {
    e.preventDefault();

    if (this.filterOptions.disabledFilter === false)
      delete this.filterOptions.disabledFilter;

    this.saveFilters();

    this.tableProps.page = 1;
    this.refreshList();
  }

  refreshListSearch() {
    this.tableProps.page = 1;

    this.refreshList();
  }

  viewUserDetails(email) {
    return {
      name: "UserDetails",
      params: { email: email },
    };
  }

  /*exportData(email) {
    userService
      .getUserId(email)
      .then((userRes) => {
        const userId = userRes.data;

        userService.exportData(userId, email).catch((err) => {
          console.error(err);
          displayErrorMessage(err, { general: ["user.error.exportData"] });
        });
      })
      .catch((err) =>
        displayErrorMessage(err, { general: ["user.error.getError"] })
      );
  }*/

  get isOpenditRole() {
    return (
      this.$store.getters["internalusers/roleUserData"]?.at(0) ===
      "ROLE_OPENDIT"
    );
  }

  @Watch("isOpenditRole")
  // eslint-disable-next-line
  onChangeRole(v): void {
    this.refreshList();
  }
}
</script>
