import { Vue, Component } from "vue-property-decorator";
import BreadCrumb from "@/ui/components/BreadCrumb/BreadCrumb.vue";
import { RouterLinkInterface } from "@/ui/interfaces/router";
import PairingController from "@/domain/pairing/PairingController";
import PairingDeviceEntity from "@/domain/pairing/PairingDeviceEntity";
import DateHelper from "@/ui/helpers/DateHelper";
import SubscriptionWifiController from "@/domain/subscriptionWifi/SubscriptionWifiController";
import DeviceCacheReaderController from "@/domain/deviceCacheReader/DeviceCacheReaderController";
import router from "@/router";
import SubscriptionNoWifiController from "@/domain/subscriptionNoWifi/SubscriptionNoWifiController";
import ActivateTrialDialog from "./ActivateTrialDialog/ActivateTrialDialog.vue";
import ExtendTrialDialog from "./ExtendTrialDialog/ExtendTrialDialog.vue";

@Component({
  components: {
    BreadCrumb,
    ActivateTrialDialog,
    ExtendTrialDialog,
  },
})
export default class UserSubscriptionDetails extends Vue {
  public pairing: PairingDeviceEntity | null = null;
  dateHelper = new DateHelper();
  public breadCrumbItems: Array<{ text: string; to?: RouterLinkInterface }> = [
    { text: this.$t("menu.users").toString(), to: { name: "Users" } },
    {
      text: this.$t("menu.userDetails").toString(),
      to: {
        name: "UserDetails",
        params: {
          email: this.$route.params.email,
        },
      },
    },
    { text: this.$t("userSubscriptionDetails.title").toString() },
  ];

  private ctrlSubNoWifi = new SubscriptionNoWifiController();

  mounted() {
    this.loadData();
  }

  private loadData() {
    const ctrlPairing = new PairingController();
    ctrlPairing
      .getPairingById(this.$route.params.pairingId)
      .then(async (dataPairing) => {
        const ctrlDeviceCacheReader = new DeviceCacheReaderController();
        await ctrlDeviceCacheReader
          .getDeviceById(dataPairing.deviceId)
          .then((dataDevice) => {
            dataPairing.setDeviceCacheReader(dataDevice);
          });

        if (dataPairing.isWifi()) {
          const ctrlSubscriptionWifi = new SubscriptionWifiController();
          await ctrlSubscriptionWifi
            .getSubscriptionByDeviceIdUserId(
              dataPairing.deviceId,
              dataPairing.userId
            )
            .then((subscriptionWifi) => {
              dataPairing.setSubscriptionWifi(subscriptionWifi);
              this.pairing = dataPairing;
            });
        } else {
          this.pairing = dataPairing;
        }
      });
  }

  public onClickPlanDetails() {
    const planCode = (this.pairing?.getPlanId() || "").toString();
    router.push({
      name: "PlanDetails",
      params: { planCode: planCode },
    });
  }

  public visibleExtendTrialDialog = false;
  public onClickExtendTrialPeriod() {
    this.visibleExtendTrialDialog = true;
  }
  public onTrialExtended() {
    this.loadData();
  }

  public visibleActivateTrialDialog = false;
  public onClickActivateTrialPeriod() {
    this.visibleActivateTrialDialog = true;
  }
  public onTrialActivated() {
    this.loadData();
  }
}
