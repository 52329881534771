import PairingUserEntity from "./PairingUserEntity";
import { AddGuestInterface, AddMasterInterface } from "./PairingInterface";
import PairingService from "./PairingService";
import PairingDeviceEntity from "./PairingDeviceEntity";
import PairingInstallationEntity from "./PairingInstallationEntity";

export default class PairingController {
  protected service: PairingService;

  constructor() {
    this.service = new PairingService();
  }

  /**
   * Agrega un maestro a un dispositivo.
   * @param params Parámetros para agregar un maestro.
   * @returns Promise<void>
   */
  public async addMaster(params: AddMasterInterface): Promise<void> {
    return this.service.addMaster(params);
  }

  /**
   * Agrega un invitado a un dispositivo.
   * @param params Parámetros para agregar un invitado.
   * @returns Promise<void>
   */
  public async addGuest(params: AddGuestInterface): Promise<void> {
    return this.service.addGuest(params);
  }

  /**
   * Elimina un usuario asociado a un dispositivo (master, invitado o soporte) de un dispositivo.
   * @param pairingId Id del emparejamiento.
   * @param reason Razón por la que se elimina el usuario.
   * @returns Promise<void>
   */
  public async delPairedUser(pairingId: string, reason: string): Promise<void> {
    return this.service.delPairedUser(pairingId, reason);
  }

  /**
   * Obtiene los emparejamientos de un dispositivo.
   * @param deviceId Id del dispositivo.
   * @returns Promise<PairingUserEntity[]>
   */
  public async getPairingByDeviceId(
    deviceId: string
  ): Promise<PairingUserEntity[]> {
    const data = await this.service.getPairingByDeviceId(deviceId);
    return data.map((pairing) => new PairingUserEntity(pairing));
  }

  /**
   * Obtiene los emparejamientos de un usuario.
   * @param userId Id del usuario.
   * @returns Promise<PairingDeviceEntity[]>
   */
  public async getPairingByUserId(
    userId: string
  ): Promise<PairingDeviceEntity[]> {
    const data = await this.service.getPairingByUserId(userId);
    return data.map((pairing) => new PairingDeviceEntity(pairing));
  }

  /**
   * Obtiene un emparejamiento por su id.
   * @param id Id del emparejamiento.
   * @returns Promise<PairingDeviceEntity>
   */
  public async getPairingById(id: string): Promise<PairingDeviceEntity> {
    const data = await this.service.getPairingById(id);
    return new PairingDeviceEntity(data);
  }

  /**
   * Obtiene los emparejamientos de una instalación.
   * @param installationId Id de la instalación.
   * @returns Promise<PairingInstallationEntity>
   */
  public async getPairingByInstallationId(
    installationId: string
  ): Promise<PairingInstallationEntity[]> {
    const data = await this.service.getPairingByInstallationId(installationId);
    return data.map((pairing) => new PairingInstallationEntity(pairing));
  }
}
