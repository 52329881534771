import { AxiosHelper } from "../helpers/AxiosHelper";
import {
  AddGuestInterface,
  AddMasterInterface,
  PairingDeviceInterface,
  PairingInstallationInterface,
  PairingUserInterface,
} from "./PairingInterface";

export default class PairingService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/pairing/api";
  }

  /**
   * Agrega un maestro a un dispositivo.
   * @param params Parámetros para agregar un maestro.
   * @returns Promise<void>
   */
  public async addMaster(params: AddMasterInterface): Promise<void> {
    const url = this.urlBase + "/v4/pairings/master/noconnected/admin";
    return this.axiosService.post(url, params);
  }

  /**
   * Agrega un invitado a un dispositivo.
   * @param params Parámetros para agregar un invitado.
   * @returns Promise<void>
   */
  public async addGuest(params: AddGuestInterface): Promise<void> {
    const url = this.urlBase + "/v3/pairings/guest/admin";
    return this.axiosService.post(url, params);
  }

  /**
   * Elimina un usuario asociado a un dispositivo (master, invitado o soporte) de un dispositivo.
   * @param pairingId Id del emparejamiento.
   * @param reason Razón por la que se elimina el usuario.
   * @returns Promise<void>
   */
  public async delPairedUser(pairingId: string, reason: string): Promise<void> {
    const url = this.urlBase + "/v4/pairings/" + pairingId;
    const params = {
      reason,
    };
    return this.axiosService.delete(url, params);
  }

  /**
   * Obtiene los emparejamientos de un dispositivo.
   * @param deviceId Id del dispositivo.
   * @returns Promise<PairingUserInterface[]>
   */
  public async getPairingByDeviceId(
    deviceId: string
  ): Promise<PairingUserInterface[]> {
    const url = this.urlBase + "/v3/pairings/device/" + deviceId;
    return this.axiosService.get(url);
  }

  /**
   * Obtiene los emparejamientos de un usuario.
   * @param userId Id del usuario.
   * @returns Promise<PairingDeviceInterface[]>
   */
  public async getPairingByUserId(
    userId: string
  ): Promise<PairingDeviceInterface[]> {
    const url = this.urlBase + "/v3/pairings?userId=" + userId;
    return this.axiosService.get(url).then((data) => {
      return data.content;
    });
  }

  /**
   * Obtiene un emparejamiento por su id.
   * @param id Id del emparejamiento.
   * @returns Promise<PairingDeviceInterface>
   */
  public async getPairingById(id: string): Promise<PairingDeviceInterface> {
    const url = this.urlBase + "/v3/pairings/" + id;
    return this.axiosService.get(url);
  }

  /**
   * Obtiene los emparejamientos de una instalación.
   * @param installationId Id de la instalación.
   * @returns Promise<PairingInstallationInterface>
   */
  public async getPairingByInstallationId(
    installationId: string
  ): Promise<PairingInstallationInterface[]> {
    const url =
      this.urlBase + "/v4/pairings?size=2000&installationId=" + installationId;
    return this.axiosService.get(url).then((data) => {
      return data.content;
    });
  }
}
