import {
  SubscriptionWifiInterface,
  SubscriptionWifiPlanAdvancedInterface,
} from "./SubscriptionWifiInterface";

export default class SubscriptionWifiEntity {
  public id: string;
  public cancelAt: number;
  public cancellationReasonSubDTO: {
    reason: string;
  };
  //public device: DeviceEntity;
  public planAdvancedDTO: SubscriptionWifiPlanAdvancedInterface;
  public initialDate: number;
  public endDate: number;

  constructor(data: SubscriptionWifiInterface) {
    this.id = data.id;
    this.cancelAt = data.cancelAt;
    this.cancellationReasonSubDTO = data.cancellationReasonSubDTO;
    //this.device = new DeviceEntity(data.deviceCache);
    this.planAdvancedDTO = data.planAdvancedDTO;
    this.initialDate = data.initialDate;
    this.endDate = data.endDate;
  }

  getCommercialName(language: string): string {
    let commercialName = this.planAdvancedDTO.regionalDetailsList.find(
      (regionalDetails) =>
        regionalDetails.language.toLowerCase() === language.toLowerCase()
    )?.commercialName;
    if (
      !commercialName &&
      this.planAdvancedDTO.regionalDetailsList.length > 0
    ) {
      commercialName =
        this.planAdvancedDTO.regionalDetailsList[0].commercialName;
    }
    if (commercialName && this.isTrial()) {
      commercialName += " (trial)";
    }
    return commercialName || "";
  }

  public isTrial() {
    return this.planAdvancedDTO.plan.trial;
  }

  /*public getCurrentPlan(language: string) {
    return (
      this.getCommercialName(language) +
      " (" +
      this.planAdvancedDTO.plan.sku +
      ")"
    );
  }*/

  public getPrice() {
    return this.planAdvancedDTO.availabilityRegionList[0].price;
  }

  public getCurrency() {
    return this.planAdvancedDTO.availabilityRegionList[0].currency;
  }

  public getPlanId() {
    return this.planAdvancedDTO.planId;
  }

  /*public getPlanRenovation() {
    return this.isTrial()
      ? this.planAdvancedDTO.plan.trial.period
      : this.planAdvancedDTO.plan.renewalPeriod;
  }*/

  /*public getSku() {
    return this.planAdvancedDTO.plan.sku;
  }*/

  /*public isCanceled() {
    return (
      this.cancelAt &&
      (this.cancellationReasonSubDTO === null ||
        this.cancellationReasonSubDTO.reason !== "TRIAL_END")
    );
  }*/

  /*public getExpirationDate(): number {
    return this.endDate;
  }*/
}
