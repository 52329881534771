import { Vue, Component, Prop } from "vue-property-decorator";
import { translatePlural } from "@/lang/i18n";
import DeviceIcon from "@/ui/components/DeviceIcon/DeviceIcon.vue";

@Component({
  components: {
    DeviceIcon,
  },
})
export default class InstallationSummary extends Vue {
  @Prop(Object) value!: any;
  private tc = translatePlural;

  public items: Array<{ name: string; tooltip: string; count: number }> = [];
  mounted() {
    if (this.isPanel()) {
      this.items.push({
        name: "placa",
        tooltip: this.getPanelTooltip(),
        count: this.value.panels,
      });
    }
    if (this.isEdibox()) {
      this.items.push({
        name: "edibox",
        tooltip: this.getEdiboxTooltip(),
        count: this.value.ediboxes,
      });
    }
    this.items.push({
      name: "monitorNoWifi",
      tooltip: this.getMonitorNoWifiTooltip(),
      count: this.value.nonConnectableMonitors || 0,
    });
    this.items.push({
      name: "monitorWifi",
      tooltip: this.getMonitorWifiTooltip(),
      count: this.value.connectableMonitors || 0,
    });
    if (this.isPanel()) {
      this.items.push({
        name: "phone",
        tooltip: this.getPhoneTooltip(),
        count: this.value.phones || 0,
      });
      this.items.push({
        name: "guardUnit",
        tooltip: this.getGuardUnitTooltip(),
        count: this.value.gu || 0,
      });
    }
  }

  private isPanel() {
    return (
      this.value?.deviceMaster?.family == "PANEL" &&
      this.value?.deviceMaster?.subtype == "4G"
    );
  }

  private isEdibox() {
    return this.value?.deviceMaster?.family == "EDIBOX";
  }

  private getPanelTooltip() {
    const n = this.value.panels;
    return n + " " + this.tc("installationDetails.device.panel", n);
  }
  private getEdiboxTooltip() {
    const n = this.value.ediboxes;
    return n + " " + this.tc("installationDetails.device.edibox", n);
  }
  private getMonitorNoWifiTooltip() {
    const n = this.value.nonConnectableMonitors;
    return n + " " + this.tc("installationDetails.device.monitorNoWifi", n);
  }
  private getMonitorWifiTooltip() {
    const n = this.value.connectableMonitors;
    return n + " " + this.tc("installationDetails.device.monitorWifi", n);
  }
  private getPhoneTooltip() {
    const n = this.value.phones;
    return n + " " + this.tc("installationDetails.device.phone", n);
  }
  private getGuardUnitTooltip() {
    const n = this.value.gu;
    return n + " " + this.tc("installationDetails.device.guardUnit", n);
  }
}
