import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import MyDialog from "primevue/dialog";

@Component({
  components: {
    MyDialog,
  },
})
export default class FrmxDialog extends Vue {
  @Prop({ default: false }) public value!: boolean;

  public visibleDialog = false;

  @Watch("value")
  private onValueChange(value: boolean) {
    this.visibleDialog = value;
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  mounted() {
    this.visibleDialog = this.value;
  }
}
