<template lang="html" src="./UserDetails.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { displayErrorMessage } from "@/api";
import userService from "@/api/user";
import rexistroService from "@/api/rexistro";

import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppListMenu from "@/components/AppListMenu.vue";
import AppChipInfo from "@/components/AppChipInfo.vue";
import HeatMap from "@/components/HeatMap/HeatMap.vue";

import PairingStatus from "@/views/Devices/DeviceDetails/PairingStatus/PairingStatus.vue";
import SubscriptionEvents from "./SubscriptionEvents/SubscriptionEvents.vue";

import AppInlineFieldEdit from "@/components/AppInlineFieldEdit.vue";
import AppEventList from "@/components/AppEventList.vue";

import dateMixin from "@/mixins/date.mixin";

import languagesMixin from "@/mixins/languages.mixin";
import detailsMixin from "@/mixins/details.mixin";
import planMixin from "@/mixins/plan.mixin";
import countriesMixin from "@/mixins/countries.mixin";

import AppDeviceIcon from "@/components/AppDeviceIcon.vue";

import moment from "moment";

import PairedDeviceTable from "./PairedDeviceTable/PairedDeviceTable.vue";

@Component({
  components: {
    AppBreadcrumbs,
    AppDeviceIcon,
    AppListMenu,
    AppChipInfo,
    AppInlineFieldEdit,
    PairingStatus,
    SubscriptionEvents,
    HeatMap,
    AppEventList,
    PairedDeviceTable,
  },
  mixins: [dateMixin, languagesMixin, detailsMixin, planMixin, countriesMixin],
  data() {
    return {
      email: this.$route.params.email,
    };
  },
})
export default class UsersList extends Vue {
  checkItemNotFound!: Function;
  internalizedText!: Function;

  // E-mail to get the user info
  email!: string;
  userId = null;
  showEventHistory = false;
  isLoadingEvents = false;
  eventsHistory = Array<{
    time: number;
    deviceId: string;
    type: string;
    subtype: string;
  }>();
  totalPagesEvent = 0;
  totalSizeEvent = 0;
  pageNumberEvent = 1;

  get userData() {
    return this.$store.getters["users/oneUserData"];
  }

  get menuOptions() {
    return [
      {
        icon: "mail",
        text: this.$t("userdetails.sendMail"),
        action: () => (window.location.href = "mailto:" + this.email),
        allowed: this.$ability.can("sendEmail", "users"),
      },
      {
        icon: "cloud_download",
        text: this.$t("userdetails.export"),
        action: () => this.exportData(this.userData.email),
        allowed: this.$ability.can("exportDetails", "users"),
      },
      {
        icon: "autorenew",
        text: this.$t("userdetails.resetpwd"),
        action: () => this.resetPassword(),
        allowed: this.$ability.can("resetPwd", "users"),
      },
      {
        icon: "my_location",
        iconProps: { size: 20, style: { "margin-left": "2px" } },
        text: this.$t("userdetails.resetUserLocation"),
        action: () => this.resetUserLocation(),
        allowed:
          this.userData.country &&
          this.$ability.can("resetUserLocation", "users"),
      },
      {
        icon: "fa-clipboard-list",
        iconProps: { size: 20, style: { "margin-left": "2px" } },
        text: this.$t("general.eventHistory"),
        action: () => this.openEventHistory(),
        allowed: this.$ability.can("eventHistory", "users"),
      },
    ];
  }

  mounted() {
    this.getUserId();
  }

  getUserId() {
    userService
      .getUserId(this.email)
      .then((res) => {
        this.userId = res.data;

        this.getUserData();
      })
      .catch((e) => {
        displayErrorMessage(e, {
          general: ["userdetails.error.getError"],
          notFound: ["userdetails.error.getDetailsNotFound", [this.email]],
        });

        this.checkItemNotFound(e, "users");
      });
  }

  getUserData() {
    this.$store.dispatch("users/getUser", {
      id: this.userId,
    });
  }

  exportData(email) {
    userService
      .exportData(this.userId, email)
      .catch((err) =>
        displayErrorMessage(err, { general: ["user.error.exportData"] })
      );
  }

  resetPassword() {
    userService
      .resetPassword(this.email)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("userdetails.resetPasswordInfo"),
        });
      })
      .catch((err) =>
        displayErrorMessage(err, { general: ["user.error.exportData"] })
      );
  }

  getDeviceInvoiceId(device) {
    let value = null;

    if (device.lastInvoice) value = device.lastInvoice.billingId;

    return value;
  }

  async openEventHistory() {
    this.isLoadingEvents = true;
    this.pageNumberEvent = 1;
    this.eventsHistory = [];
    this.showEventHistory = true;
    await this.loadEvents();
    this.isLoadingEvents = false;
  }

  resetUserLocation() {
    userService
      .resetLocation(this.userId)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("userdetails.resetLocationSuccess"),
        });

        this.getUserId();
      })
      .catch((err) =>
        displayErrorMessage(err, {
          general: ["userdetails.error.resetLocation"],
        })
      );

    this.userId = null;
  }

  loadMoreEvents(page) {
    this.pageNumberEvent = page;
    this.loadEvents();
  }

  viewDeviceDetails(serialNumber) {
    return {
      name: "DeviceDetails",
      params: { deviceId: serialNumber },
    };
  }

  loadEvents() {
    return rexistroService
      .getEventHistory("userId=" + this.userId, this.pageNumberEvent)
      .then((res) => {
        this.totalPagesEvent = res.data.totalPages;
        this.totalSizeEvent = res.data.totalElements;
        this.eventsHistory.push(...res.data.content);
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.errorGetInfo"],
        });
        throw err;
      });
  }

  formatDate(date) {
    return date ? moment(date).format("DD/MM/YYYY") : "";
  }
  formatDateTime(date) {
    date ? moment(date).format("DD/MM/YYYY HH:mm:ss") : "";
  }
}
</script>
<style scoped>
.user-map {
  height: calc(100% - 60px);
}
</style>
<style>
.table-height-100 {
  height: 100%;
}
.table-height-100 .v-table__overflow {
  height: calc(100% - 65px);
}

.row.user-info {
  max-width: 1200px;
}

.row.user-info > .col > .layout.row .layout.column {
  height: 52px;
  justify-content: space-around;
}
</style>
